:root{
  --main-color:rgb(94, 221, 173);
  --font-color:#777;
  
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

/*nav*/

.head-nav-container{
  background-color: #232323;
  color:white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}



.head-nav-container ul{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style: none; 
  width: 100%;

}

.head-nav-container ul li{
  flex-basis: 20%;
  padding: 10px;
  border-right: 1px solid white;
  text-align: center;
  color: rgb(94, 221, 173);
}

.head-nav-container ul li i{
  margin-right: 5px;
}

.head-nav-container ul li a{
  color:rgb(94, 221, 173);
}

.nav-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(239, 239, 239);
  gap:20px;
  
}



.nav-logo{
  flex-basis: 150px;
  margin: 30px;
}

.nav-logo img{
  width:65%;

}


.nav-elements{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style: none;
  
}

.nav-elements li{
  margin: 14px;
  font-size: 1.5rem;
}

.nav-elements li a{
  text-decoration: none;
  color:black;
  font-weight: 800;
}

/*Link*/

.link{
  
  text-decoration: none;
  color:black;
  background-color: var(--main-color);
  padding: 10px 20px;
  border-radius: 20px;
  border: 2px solid black;
  transition: 0.4s ease;
  
}

.link:hover{
  background-color: black;
  color: var(--main-color);
  border: 2px solid var(--main-color);
}

.tour-link{
  margin-top: 30px;
}


/*header*/


.header-container{
  width: 100vw;
  height: 80vh;
  background-image: url('./img/header.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  filter: grayscale(0.2);
  overflow: hidden;
  
}

.header-info{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* font-size: 3rem; */
  background-color: rgba(0, 0, 0, 0.582);
  color:white;
 
}

.header-info h1{
  margin-top:10%;
  font-size: 5.5rem;

}

.header-info button{
  margin: 20px;
  padding: 10px;
  letter-spacing: 2px;
  border: 2px solid black;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.216);
  color:white;
  font-size: 30px;
  cursor: pointer;
  transition: 0.6s ease;

}

.header-info button:hover{
  background-color: rgba(0, 0, 0, 0.521);
}


/*main*/

.main-container{
  position: relative;
  margin-top: 50px;
}

.main-container i{
  position: absolute;
  top:10px;
  border: 2px solid var(--main-color);
  padding: 50px;
  border-radius: 50px;
  z-index: 200;
  background-color: white;

}

.main-items{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

}

.main-item{
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  text-align: center;
  padding: 30px;
  border: 2px solid var(--main-color);
  border-radius: 10px;
  
  
  
}

.main-item h3{
  margin-top:50px;
}

.main-item p{
  color:var(--font-color);
  font-size: 14px;
}

.main-about{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  background-color: var(--main-color);
  
}

.main-img{
  width: 100%;
  height: 100%;
  margin: 50px;
  
}

.main-about p{
  font-size: 1.26rem;
  color: rgb(40, 40, 40);
}

.main-img img{
  object-fit: cover;
  width: 50vw;
}

.main-count{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 2.2rem;
  margin-top: 50px;
  margin-bottom: 150px;
  
}

.count-item{
  border-radius: 20px;
  padding: 20px;
  background-color: var(--main-color);
  box-shadow: 2px 2px 2px black;
  color:white;
  text-align: center;
}

.count-item p{
  font-size: 2rem;
}

/* tours  */

.tours-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tours-container h1{
  margin-top: 50px;
}

.tours-grid{
  margin-top:50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  
}

.tour-item{
  width: 30vw;
  text-align: center;
  padding: 20px;
}

.tour-header{
  text-align: center;
  padding: 5px;
  background-color: var(--main-color);
  margin: 10px;
  border: 2px solid black;
  border-radius: 20px;
 }

.tour-item img{
  width: 100%;
  height: 300px;
  object-fit:cover;
  border: 2px solid rgb(94, 221, 173);
  border-radius: 100px;
  padding: 10px;
  transition: 0.6s ease;
}

.tour-item img:hover{
  padding: 0;
  
}


/*contact*/

.contact-container{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-image: url('./img/header.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  filter: grayscale(0.2);
  margin-top: 50px;
  height: 50vh;
  border-top: 2px solid black;
}

.contact-left{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-basis: 35%;
  padding: 10px;
  background-color: var(--main-color);
  filter: opacity(0.9);
  color:black;
  text-align: center;
  padding: 20px;
}

.contact-right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 20%;
  background-color: var(--main-color);
  filter: opacity(0.9);
  
}

.contact-right h2{
  transition: 0.6s ease;
  cursor: pointer;
}

.contact-right h2:hover{
  font-size: 30px;
  
}

.contact-right a{
  text-decoration: none;
  color:black;
}

.contact-right img{
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.contact-right h3{
  text-align: center;
}

/*footer*/

.footer-container{
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: black;
  color: var(--main-color);
  padding: 5px;
}

/*one tour site*/

.tour-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 10%;
}

.tour-header{
  text-align: center;
  padding: 10px;
}

.tour-main{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap:20px;
  padding: 10px;
  
}

.tour-info{
  padding:auto 50px;
}

.tour-img{
  width: 40%;
 

}

.tour-rate{
  border: 1px solid var(--font-color);
  text-align: center;
  width: 20%;
}

.tour-rate p{
  font-size: 0.9rem;
  padding: 3px;
}

.rate{
  border: 1px solid var(--font-color);
  background-color: rgb(49, 49, 255);
  color:white;
  padding: 10px;
}

.opinion{
  font-weight: 800;
}

.opinion-text{
  padding: 6px;
}

.map{
  width: 100%;
  object-fit: contain;
  padding: 10px;
  
}

.tour-img img{
  width: 100%;
  height: 400px;
  object-fit:cover;
}

.tour-info{
  width: 50%;
}



.img{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  gap:10px;
  height: 27vh;
}

.img img{
  width: 32.5%;
  object-fit: cover;
}


/* price*/

.price-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: auto 50px; */
  background-color: #818181;
}

.price-container h1{
  text-align: center;
  color:var(--main-color);
  text-shadow: 2px 2px 3px black;
  padding: 5px;
  width: 100%;
  border-bottom: 2px solid var(--main-color);
}

.price-container h2{
  text-align: center;
}

.ticket-container{
  display: flex;
}

.ticket-header{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ticket-img{
  flex-basis: 50%;
  height: 100vh;
}

.ticket-info{
  flex-basis: 50%;
  padding: 20px;
}

.ticket-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form{
  display: flex;
  justify-content: center;
  background-image: url('./img/form2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  color: white;
}

.form-container{
  width: 30%;
  height: 80%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #232323b1;
  padding-top: 50px;
  border-radius: 10px;
  
 
}

.form-container input{
  margin: 10px;
  border-radius: 10px;
  line-height: 30px;
  padding: 5px;
}

.form-container p{
  margin: auto;

}

.form-container button{
  padding: 10px;
  border-radius: 10px;
  width: 100px;
}

.form-container textarea{
  width: 250px;
  
 
}

@media screen and (max-width:1040px) {
  .nav-elements li a{
    font-size: 1rem;
    padding: 10px 14px;
  }

  .nav-logo{
    margin: 10px;

  }
}



@media screen and (max-width:460px) {
  
   .head-nav-container ul{
      /* justify-content: space-around; */
      align-items: center;
      
   }

   .head-nav-container ul li{
    border: none;
    font-size: 12px;
   }

   .head-nav-container ul :nth-child(1){
    flex-basis: 40%;
   }

   .nav-logo{
    margin: 10px 0;
   }

   .nav-logo img{
    width: 150px;
    flex-basis: auto;
   }

   .nav-container{
      flex-direction: column;
   }

   .nav-elements{
    flex-direction: column;
    padding: 0;
   }

    .nav-elements li a{
      font-size: 1.3rem;
      padding: 10px 14px;
    }

   .header-container{
    height: 50vh;
   }

   

   .header-info h1{
    font-size: 2.5rem;
    margin-top: 20%;
   }

   .main-container i{
    position: relative;
   }

   .main-items{
    flex-direction: column;
   }

   .main-item{
    width: 90%;
   }

   .main-count{
    padding: 10px;
   }

   

   .main-about{
   flex-direction: column;

   }

   .main-about p{
   text-align: center;
   font-size: 1.2rem;
   padding: 25px;
   }

   .main-img{
   width: auto;
   height: auto;
   margin: 10px;
   
   }

   .main-img img{
    margin-top: 30px;
    border-radius: 10px;
    width: 70vw;
   }

   .tours-grid{
   flex-direction: column;
   }

   .tour-item{
    width: auto;
   }

   .contact-container{
    flex-direction: column;
    height: auto;
   
   }

   .tour-container{
    margin: auto;
   }


   .tour-main{
    flex-direction: column;
   }

   .tour-header{
    text-align: center;
    padding: 10px;
    background-color: var(--main-color);
    margin: 10px;
    border: 2px solid black;
    border-radius: 20px;
   }

   .tour-img{
    width: auto;
   }

   .tour-info{
    width: auto;
    text-align: center;
   }

   .tour-rate{
    width: auto;
   }

   .img{
    flex-direction: row;
    
   }

   .img img{
    width: 100%;
   }

   .count-item p{
    font-size: 13px;
   }

   .count-item span{
    font-size: 1.5rem;
   }

   .price-container{
    font-size: small;
    margin: auto;
   }

   .ticket-header{
    flex-direction: column;
   }

   .form-container{
    width: 100%;
    height: 100vh;
    position:unset; 
    transform: none;
    background-color: #2323238b;
   }

   #tours{
    margin: 10px;
   }

  
} 
  







